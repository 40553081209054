import React from "react";
import { Helmet } from "react-helmet";
import { graphql, PageProps } from "gatsby";
import { FeatureRemark } from "../types/feature-markdown";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/features/Hero";
import TextCTA from "../components/Content/TextCTA";
import Testimonial from "../sections/landing1/Testimonial";
import FAQ from "../components/Content/Faq";
import ImageRight from "../components/Content/ImageRight";
import ImageLeft from "../components/Content/ImageLeft";

type GraphQLResult = {
  markdownRemark: FeatureRemark;
};

const About: React.FC<PageProps<GraphQLResult>> = ({ data }) => {
  const result = data.markdownRemark.frontmatter;

  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>{result.title}</title>
          <meta
            name="description"
            content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK."
          />
        </Helmet>
        <Hero result={result}></Hero>
        <ImageRight
          buttons={[]}
          text={result.featureText1}
          title={result.featureTitle1}
        />
        <ImageLeft
          buttons={[]}
          text={result.featureText2}
          title={result.featureTitle2}
        />
        <TextCTA
          buttonText={result.ctaBtnText}
          text={result.ctaText}
          title={result.ctaTitle}
        />
        <ImageRight
          buttons={[]}
          text={result.featureText3}
          title={result.featureTitle3}
        />
        <ImageLeft
          buttons={[]}
          smallPreTitle={result.featurePreTitle4}
          text={result.featureText4}
          title={result.featureTitle4}
        />
        <Testimonial />
      </PageWrapper>
    </>
  );
};
export default About;

export const query = graphql`
  query feature($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        heroTitle
        heroText
        featureTitle1
        featureText1
        featureTitle2
        featureText2
        featureTitle3
        featureText3
        featurePreTitle4
        featureTitle4
        featureText4
        ctaTitle
        ctaText
        ctaBtnText
        ctaBtnLink
      }
    }
  }
`;
