import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { WhoMarkdownRemark } from "../../types/whoWeHelp-markdown";
import { Title, Section, Button } from "../../components/Core";
import imgCase1 from "../../../assets/image/heros/town.png";

const SectionStyled = styled(Section)`
  /* background: url(${imgCase1}) no-repeat center right; */
  position: relative;
  background-color: #f0f9fe;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.text};
  font-size: 20px;
`;

const Container = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1400px;
  }
`;

const Hero: React.FC<WhoMarkdownRemark> = ({ result }) => {
  const { heroTitle, heroText } = result;
  return (
    <>
      <SectionStyled
        pt={["100px!important", null, "100px!important"]}
        pb={["0px!important", null, "0px!important"]}
      >
        <Container>
          <Row>
            <Col lg="6">
              <div style={{ paddingTop: 100, paddingBottom: 100 }}>
                <Title
                  lineHeight="64px !important"
                  variant="hero"
                  color="#021d2d"
                >
                  {heroTitle}
                </Title>
                <Text>{heroText}</Text>
                <Button mt="30px" bg="primary" variant="solid">
                  Book a demo
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "#075c8d",
                }}
              />
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};
export default Hero;
